import React, {Component} from 'react';
import {doQbAuthorize} from "../services/opero";
import {Loader} from "semantic-ui-react";
import {ErrorMessage} from "../components/messages/errorMessage";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {getLocalStorage, setLocalStorage} from "../utils/common";
const MySwal = withReactContent(Swal)

class QbSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
        }
    }

    componentDidMount() {
        const {pmId, orgId, openedPaymentId, testMode} = getLocalStorage();
        this.init(pmId, openedPaymentId, orgId, testMode).then();
    }

    init = async (pmId, openedPaymentId, orgId, testMode) => {
        const params = new URLSearchParams(this.props.location.search);
        const realmId = params.get("realmId");
        const authCode = params.get("code");
        try {
            await doQbAuthorize(realmId, authCode, pmId, openedPaymentId, orgId, testMode);
            setLocalStorage({});
            this.setState({
                loading: false,
                error: null
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e,
            })
        }
    }

    render() {
        const {loading, error} = this.state;
        if (loading) {
            return <Loader active>Loading</Loader>
        } else if (error) {
            return <ErrorMessage message={error.message}/>
        }
        else{
            MySwal.fire({
                title: 'Authorized!',
                html: '<p>Your quickbooks account has been authorized</p><br/><br/>',
                icon: 'success',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: '#fff',
                //timer: 10000,
                /*willClose: () => {
                    window.location.href = this.state.completionUrl;
                }*/
            })
        }

        return (
            <React.Fragment>
                Connect QB Success
            </React.Fragment>
        )
    }
}

export default QbSuccess;

import React, {Component} from 'react';
import {Loader} from "semantic-ui-react";
import {oauthCallback} from "../services/opero";
import { withRouter } from "react-router-dom";

class OauthCallback extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const code = params.get("code");
        const state = params.get("state");
        oauthCallback(code, state).then(() => {
                this.props.history.push("success");
            }
        ).catch(() => {
            this.props.history.push("error");
        });
    }

    render() {
        return <Loader active centered/>;
    }
}

export default withRouter(OauthCallback);

import React, {Component} from 'react';
import {setLocalStorage} from "../utils/common";
import {doIsOrgConnected, doQbRevoke} from "../services/opero";
import {ErrorMessage} from "../components/messages/errorMessage";
import {Loader} from "semantic-ui-react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal)

class QbDisconnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        }
    }

    async componentDidMount() {
        this.setState({error: null});

        const params = new URLSearchParams(this.props.location.search);
        const orgId = params.get('orgId');
        const pmId = params.get('pmId');
        if (orgId.length > 0 && pmId.length > 0) {
            try {
                await doIsOrgConnected({orgId, pmId});
                this.setState({
                    error: null,
                });
                setLocalStorage({pmId: pmId, orgId: orgId, openedPaymentId: null, testMode: null});
                doQbRevoke(orgId, pmId).then();
            } catch (err) {
                this.setState({loading: false, error: {message: "SF organization not connected"}});
            }
        } else {
            this.setState({loading: false, error: {message: "The url is invalid"}});
        }
    }

    render() {
        const {loading, error} = this.state;
        if (loading) {
            return <Loader active>Loading</Loader>
        } else if (error) {
            return <ErrorMessage message={error.message}/>
        }
        else{
            MySwal.fire({
                title: 'Disconnected!',
                html: '<p>Your quickbooks account has been disconnected</p><br/><br/>',
                icon: 'success',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: '#fff',
                //timer: 10000,
                /*willClose: () => {
                    window.location.href = this.state.completionUrl;
                }*/
            })
        }

        return (
            <React.Fragment>&nbsp;</React.Fragment>
        )


    }
}

export default QbDisconnect;

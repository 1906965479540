import React,{ useState }  from "react"

export default function FuturesPayment(props) {

    const [isChecked, setIsChecked] = useState(false);
    const handleOptionChange =  function (changeEvent) {
        if(changeEvent.target.value==="yes"){
            props.setFuturePayments(true);
        }
        else{
            props.setFuturePayments(false);
        }
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
        props.setFuturePayments(!isChecked);
    }

    return (
        <div>
            <fieldset className="form-group row m-0" id="form-group-future-payments">
                { props.forceSaveMop === true ?
                    <div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={isChecked} name="futurePayments" id="gridRadios1" onChange={handleCheckboxChange}  />
                            <label className="form-check-label" htmlFor="gridRadios1">
                                { `I authorize ${props.companyName} to initiate entries to the bank account/credit card above in order to pay amount due for future invoices. I represent that I have authority to bind the organization that owns the bank account/credit card, and to authorize all transactions to the bank account/credit card.`}
                            </label>
                        </div>
                    </div>
                    :
                    <div className="col-sm-12">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="futurePayments" id="gridRadios1" value="yes" defaultChecked onChange={handleOptionChange}/>
                            <label className="form-check-label" htmlFor="gridRadios1">
                                { `I authorize ${props.companyName} to initiate entries to the bank account/credit card above in order to pay amount due for future invoices. I represent that I have authority to bind the organization that owns the bank account/credit card, and to authorize all transactions to the bank account/credit card.`}
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="futurePayments" id="gridRadios2" value="no" onChange={handleOptionChange}/>
                            <label className="form-check-label" htmlFor="gridRadios2">
                                Do not save my payment information for future invoices.
                            </label>
                        </div>
                    </div>
                }
            </fieldset>
            { props.formErrors.futurePayments ?
                <div className="text-danger" style={{ marginTop: '-20px', marginBottom: '20px'}}>{props.formErrors.futurePayments}</div>
                : null }
        </div>
    );
}

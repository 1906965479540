import React, {Component} from 'react';
import {Button, Loader} from "semantic-ui-react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import './paymentForm.css'
import {Background} from "../components/background/background";
import CheckoutFormUpdateMop from "../components/forms/checkOutFormUpdateMop";
import {ErrorMessage} from "../components/messages/errorMessage";
import "react-multi-email/style.css";
import {catchPrepareAppError, doPrepareApp, tokenizeQBCc, upsertMop} from '../services/opero';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import {emailPattern, parsePaymentFormQueryParams, validateCCNumber} from "../utils/common";
import FuturesPaymentText from "../components/forms/futuresPaymentText";
const MySwal = withReactContent(Swal)
const recaptchaRef = React.createRef();

class PaymentFormUpdateMop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            formErrors: {},
            orgId: null,
            openedPaymentId: null,
            completionUrl: null,
            paymentMethods:[],
            paymentMethodId:null,
            theme: null,
            firstName:"",
            lastName:"",
            email:"",
            additionalEmail:"",
            additionalEmail2:"",
            futurePayments:true,
            validBillingData:true,
            invoiceInfo: null,
            relatedTo:null,
            chargeDescription:null,
            amount:'',
            currency:'',
            rawAmount:0,
            bank_account_routing: "",
            bank_account_number: "",
            bank_account_name: "",
            bank_account_phone: "",
            bank_account_type:"BUSINESS_CHECKING",
            card_name:"",
            card_number:"",
            card_expMonth:"",
            card_expYear:"",
            card_cvc:"",
            skipValidate: 0,
            recaptchaToken: null,
            stripePublishableKey:null,
            qbCustomerId: null,
        };
    }

    async componentDidMount() {
        document.title = "Update MOP";
        this.setState({loading: true, error: null});
        const [params, queryParamsData] = parsePaymentFormQueryParams(this.props.location.search);
        this.setState({theme: params.get('theme'), completionUrl: queryParamsData.completionUrl });
        if (queryParamsData.orgId) {
            try {
                const {...prepareAppResponse} = await doPrepareApp(queryParamsData);
                console.log(prepareAppResponse)
                if (prepareAppResponse.paymentMethods){
                    prepareAppResponse.paymentMethods.push({'id':'current', stripePublishableKey: null, default:true, active:true, 'name':"Keep my payment method" });
                    const selected= prepareAppResponse.paymentMethods.filter((pm) => { return pm.default===true })
                    if (selected.length>0){
                       prepareAppResponse.paymentMethodId=selected[0].id
                    }
                }
                this.setState({
                    loading: false,
                    error: null,
                    ...prepareAppResponse,
                });

            } catch (err) {
                err = catchPrepareAppError(err);
                this.setState({loading: false, error: err});
            }
        } else {
            this.setState({loading: false, error: {message: "The payment method update url is invalid"}});
        }
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
        if (name ==="paymentMethodId"){
            const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
            this.setState({stripePublishableKey: currentPM[0].stripePublishableKey});
        }
        return true
    }

    handleStripePromise (){
        const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
        if (currentPM[0].stripePublishableKey){
            return loadStripe(currentPM[0].stripePublishableKey);
        }
        else{
            return false;
        }

    }

    validate(){
        let errors = {};
        let isValid = true;

        if (!this.state.skipValidate) {
            if (this.state.firstName.trim().length < 2) {
                isValid = false;
                errors["firstName"] = "Please enter your name.";
            }

            if (this.state.lastName.trim().length < 2) {
                isValid = false;
                errors["lastName"] = "Please enter your last name.";
            }

            if (!emailPattern.test(this.state.email)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }

            if (this.state.additionalEmail.trim().length !== 0 && !emailPattern.test(this.state.additionalEmail)) {
                isValid = false;
                errors["additionalEmail"] = "Please enter valid additional email address.";
            }

            if (this.state.additionalEmail2.trim().length !== 0 && !emailPattern.test(this.state.additionalEmail2)) {
                isValid = false;
                errors["additionalEmail"] = "Please enter valid additional email address.";
            }

            if (!this.handleStripePromise() && this.state.paymentMethodId!=='current') {
                if (this.handleIsQBBank()) {
                    if (this.state.bank_account_routing.trim().length !== 9) {
                        isValid = false;
                        errors["bank_account_routing"] = "ACH routing number invalid.";
                    }

                    if (this.state.bank_account_number.trim().length < 7) {
                        isValid = false;
                        errors["bank_account_number"] = "ACH account number invalid.";
                    }

                    if (this.state.bank_account_name.trim().length < 2) {
                        isValid = false;
                        errors["bank_account_name"] = "Please enter your bank account name.";
                    }

                    if (this.state.bank_account_phone.trim().length < 7) {
                        isValid = false;
                        errors["bank_account_phone"] = "Please enter your bank account phone.";
                    }
                }
                else{
                    if (this.state.card_name.trim().length < 2) {
                        isValid = false;
                        errors["card_name"] = "Please enter a valid card name.";
                    }
                    if (this.state.card_number.trim().length < 10) {
                        isValid = false;
                        errors["card_number"] = "Please enter a valid card number.";
                    }
                    if (!validateCCNumber(this.state.card_number.trim())){
                        isValid = false;
                        errors["card_number"] = "Please enter a valid card number.";
                    }
                    if (this.state.card_expMonth.trim().length!==2) {
                        isValid = false;
                        errors["card_expMonth"] = "Please enter a valid expire month.";
                    }
                    if (this.state.card_expYear.trim().length!==2) {
                        isValid = false;
                        errors["card_expYear"] = "Please enter a valid expire year.";
                    }
                    if (this.state.card_cvc.trim().length < 2) {
                        isValid = false;
                        errors["card_cvc"] = "Please enter a valid CVC.";
                    }
                }

            }


            if (!this.state.recaptchaToken){
                isValid = false;
                errors["recaptcha"] = "Please complete the recaptcha.";
            }

            this.setState({
                formErrors: errors,
                validBillingData: isValid
            });
        }
        return Object.keys(errors).length
    }

    setFuturePayments =  (childData) => {
        this.setState({futurePayments: childData})
    }

    clearOnRecaptcha =  () => {
        this.setState({ recaptchaToken: null })
    }

    onRecaptchaChange = (value) => {
        this.setState({ recaptchaToken: value })
    }

    handleIsQBBank(){
        const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
        return currentPM[0].type === "BANK" && currentPM[0].platform === "QB";
    }

    async upsertMopQb(){
        let errors = this.validate();
        if (errors===0){

            let selectedPaymentMethod=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId })[0]
            if (selectedPaymentMethod.id==='current') {
                selectedPaymentMethod = null
            }

            MySwal.fire({
                imageUrl: './images/Spinner.gif',
                imageHeight: 120,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            if (!this.handleIsQBBank() && selectedPaymentMethod!==null){
                const request = {
                    name: this.state.card_name,
                    number: this.state.card_number,
                    expMonth: this.state.card_expMonth,
                    expYear: "20"+this.state.card_expYear,
                    cvc: this.state.card_cvc,
                    email: this.state.email,
                    openedPaymentId: this.state.openedPaymentId,
                    selectedPaymentMethod: this.state.paymentMethods.filter((pm) => pm.id === this.state.paymentMethodId)[0],
                }
                try {
                    let result = await tokenizeQBCc(request);
                    if (result.status === 200) {
                        this.setState({quickBooksCcId: result.data.id, qbCustomerId: result.data.customerId});
                    }
                }
                catch (error){
                    let html=error.response.data.message || '';
                    if (error.response.data.code==="QB_TOKENIZE_ERR" || error.response.data.code==="QB_ERR"){
                        let message=error.response.data.message
                        try{
                            message=JSON.parse(error.response.data.message)
                        }
                        catch{}
                        if(message.errors!== undefined) {
                            html=""
                            message.errors.forEach(function (value) {
                                if (value.moreInfo!==undefined){
                                    html = html + value.moreInfo + "<br/>"
                                }
                                else if ( value.message!==undefined){
                                    html = html + value.message + "<br/>"
                                }
                            });
                        }
                    }

                    MySwal.fire({
                        title: 'The card could not be processed',
                        html: html,
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })
                    return false;
                }
            }

            const request = {
                openedPaymentId: this.state.openedPaymentId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                additionalEmail: this.state.additionalEmail,
                additionalEmail2: this.state.additionalEmail2,
                relatedTo: this.state.relatedTo,
                BankAccountRouting: this.state.bank_account_routing,
                enableFuturePayments: this.state.futurePayments,
                BankAccountNumber: this.state.bank_account_number,
                BankAccountName: this.state.bank_account_name,
                BankAccountPhone: this.state.bank_account_phone,
                BankAccountType: this.state.bank_account_type,
                selectedPaymentMethod: selectedPaymentMethod,
                quickBooksCcId: this.state.quickBooksCcId,
                recaptchaToken: this.state.recaptchaToken,
                last4: this.state.card_number.substr(this.state.card_number.length - 4),
                expMonth: this.state.card_expMonth,
                expYear: "20"+this.state.card_expYear,
                qbCustomerId: this.state.qbCustomerId,
            }

            try{
                let result = await upsertMop(request);
                if (result.status === 200){
                    if (this.state.completionUrl){
                        MySwal.fire({
                            title: 'Billing Information Saved!',
                            html: '<p>The billing information entered has been successfully saved</p></p><p>You will be redirected in a few seconds.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            timer: 10000,
                            willClose: () => {
                                window.location.href = this.state.completionUrl;
                            }
                        })
                    }else{
                        MySwal.fire({
                            title: 'Billing Information Saved!',
                            html: '<p>The billing information entered has been successfully saved</p></p><p>You may now close this tab.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            willClose: () => {
                                window.close();
                            }

                        })
                    }
                }
            }
            catch (error) {
                recaptchaRef.current.reset();
                this.clearOnRecaptcha()

                MySwal.fire({
                    title: 'Billing Information could not be updated',
                    text: error.response.data.message ,
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                })
            }
        }
    }

    render() {
        const {loading, error, orgId} = this.state;
        if (loading) {
            return <Loader active>Loading</Loader>
        } else if (error) {
            return <ErrorMessage message={error.message}/>
        } else if (!orgId) {
            return <ErrorMessage message="The payment link is invalid"/>
        }

        let optionTemplate = this.state.paymentMethods.map(v => (
            <option key={v.id} value={v.id}>{v.name}</option>
        ));

        const QBForm = this.handleIsQBBank() ? <div>
                <div className="form-row">
                    <div className="form-group col-md-8">
                        <label htmlFor="bank_account_name" >Bank Account Name</label>
                        <input type="text" className="form-control" name="bank_account_name" placeholder="The name on the bank account" value={this.state.bank_account_name} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.bank_account_name}</div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_phone">Bank Account Phone Number</label>
                        <input type="number" className="form-control" name="bank_account_phone" placeholder="Phone number associated to your account" value={this.state.bank_account_phone} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.bank_account_phone}</div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_type">Bank Account Type</label>
                        <select className="form-control" name="bank_account_type" value={this.state.bank_account_type} onChange={(event) => this.handleUserInput(event)} >
                            <option value="BUSINESS_CHECKING">Business Checking</option>
                            <option value="BUSINESS_SAVINGS">Business Savings</option>
                            <option value="PERSONAL_CHECKING">Personal Checking</option>
                            <option value="PERSONAL_SAVINGS">Personal Savings</option>
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_number">Bank Account Number</label>
                        <input type="number" className="form-control" name="bank_account_number" value={this.state.bank_account_number} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.bank_account_number}</div>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_routing">Bank Account Routing Number</label>
                        <input type="number" className="form-control" name="bank_account_routing" value={this.state.bank_account_routing} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.bank_account_routing}</div>
                    </div>
                    <FuturesPaymentText companyName={ this.state.companyName } />
                    <div className="recaptcha">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                            onChange={this.onRecaptchaChange}
                        />
                        <div className="text-danger">{this.state.formErrors.recaptcha}</div>
                    </div>
                    <Button fluid primary type="bottom" style={{marginTop: 10}} className="btn-dark" onClick={this.upsertMopQb.bind(this)}  >Update payment method</Button>
                </div>
            </div>
            : <div>
                <div className="form-row">

                    <div className="form-group col-md-8">
                        <label htmlFor="card_number">Credit Card Number</label>
                        <input type="number" className="form-control" name="card_number" placeholder="" value={this.state.card_number} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_number}</div>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="card_expire">Expiration</label>
                        <input type="number" className="form-control" name="card_expMonth" placeholder="MM" value={this.state.card_expMonth} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_expMonth}</div>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="card_expire">&nbsp;</label>
                        <input type="number" className="form-control" name="card_expYear" placeholder="YY" value={this.state.card_expYear} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_expYear}</div>
                    </div>

                    <div className="form-group col-md-8">
                        <label htmlFor="card_name" >Name On Credit Card</label>
                        <input type="text" className="form-control" name="card_name" placeholder="" value={this.state.card_name} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_name}</div>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_number">CVC</label>
                        <input type="number" className="form-control" name="card_cvc" value={this.state.card_cvc} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_cvc}</div>
                    </div>
                </div>
                <FuturesPaymentText companyName={ this.state.companyName } />
                <div className="recaptcha">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                        onChange={this.onRecaptchaChange}
                    />
                    <div className="text-danger">{this.state.formErrors.recaptcha}</div>
                </div>
                <Button fluid primary type="bottom" style={{marginTop: 10}} className="btn-dark" onClick={this.upsertMopQb.bind(this)}  >Update payment method</Button>
            </div>

        const keepCurrentPM = <div>
            <div className="recaptcha">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                    onChange={this.onRecaptchaChange}
                />
                <div className="text-danger">{this.state.formErrors.recaptcha}</div>
            </div>
            <Button fluid primary type="bottom" style={{marginTop: 10}} className="btn-dark" onClick={this.upsertMopQb.bind(this)}  >Update Billing Contact</Button>
        </div>

        return (
            <React.Fragment>
                <Background theme={ this.state.theme }/>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div  className="col-lg-7 col-md-9 col-sm-11  mt-4 mb-4">
                            <div id="form-container" className="row p-0 mt-4 mb-4">
                                <div className="col-md-12 p-4">
                                    <div className="company-info mb-4">{ this.state.companyLogo ? <img src={ this.state.companyLogo } alt="" width={this.state.logoImageWidth} height={this.state.logoImageHeight} /> : <h2 className="m-0" id="company-name">{ this.state.companyName }</h2>  }</div>
                                    { this.state.paymentDescription && <p>{this.state.paymentDescription}</p>}
                                    <div className="card-body mb-4">
                                        <h4>Billing Contact</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="firstName" >First Name</label>
                                                <input type="text" className="form-control" name="firstName" value={this.state.firstName} onChange={(event) => this.handleUserInput(event)}  />
                                                <div className="text-danger">{this.state.formErrors.firstName}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" className="form-control" name="lastName" value={this.state.lastName} onChange={(event) => this.handleUserInput(event)}  />
                                                <div className="text-danger">{this.state.formErrors.lastName}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.email}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="additionalEmail">Additional Billing Email (optional)</label>
                                                <input type="email" className="form-control" name="additionalEmail" value={this.state.additionalEmail} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.additionalEmail}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="additionalEmail2">Additional Billing Email 2 (optional)</label>
                                                <input type="email" className="form-control" name="additionalEmail2" value={this.state.additionalEmail2} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.additionalEmail2}</div>
                                            </div>
                                        </div>
                                        <h4>Payment Method</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="methods" >Select Payment Method</label>
                                                <select className="form-control" name="paymentMethodId" value={this.state.paymentMethodId}  onChange={(event) => this.handleUserInput(event)} >
                                                    { optionTemplate }
                                                </select>
                                            </div>
                                        </div>

                                        { this.handleStripePromise() ?
                                            <Elements stripe={this.handleStripePromise()} key={this.state.stripePublishableKey} >
                                                <CheckoutFormUpdateMop companyName={ this.state.companyName }
                                                              lastName={ this.state.lastName }
                                                              firstName={ this.state.firstName }
                                                              email={ this.state.email }
                                                              openedPaymentId={ this.state.openedPaymentId }
                                                              completionUrl={ this.state.completionUrl }
                                                              validBillingData={this.state.validBillingData}
                                                              additionalEmail={this.state.additionalEmail}
                                                              additionalEmail2={this.state.additionalEmail2}
                                                              futurePayments={this.state.futurePayments}
                                                              setFuturePayments = {this.setFuturePayments}
                                                              selectedPaymentMethod = { this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId })[0] }
                                                              currency= {this.state.currency}
                                                              rawAmount= {this.state.rawAmount}
                                                              stripe={this.handleStripePromise()}
                                                              validate={this.validate.bind(this)}
                                                              formErrors={this.state.formErrors}
                                                              recaptchaToken={this.state.recaptchaToken}
                                                              onRecaptchaChange={this.onRecaptchaChange}
                                                              clearOnRecaptcha={this.clearOnRecaptcha}

                                                />
                                            </Elements>
                                            : this.state.paymentMethodId!=="current" ? <div>{ QBForm }</div> : <div>{keepCurrentPM}</div>
                                        }
                                        <p className="powerBy mt-4 mb-0">Powered by <a href="https://www.opero.com" target="blank">Opero</a> for { this.state.companyName } </p>
                                        <p className="securelyBy mt-0">Payment Processed Securely By { this.handleStripePromise() ? <i className="fab fa-stripe"> </i> : <b>QuickBooks</b>} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentFormUpdateMop;

import React from "react"
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button} from "semantic-ui-react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FuturesPayment from "./futuresPayment";
import {sendPay} from "../../services/opero";
import ReCAPTCHA from "react-google-recaptcha";
const MySwal = withReactContent(Swal)
const recaptchaRef = React.createRef();


export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        let errors = await props.validate();
        if (errors===0) {

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            MySwal.fire({
                imageUrl: './images/Spinner.gif',
                imageHeight: 120,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                MySwal.fire({
                    title: 'Oops...',
                    text: error.message,
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    CancelButtonText: "close",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                })
            } else {
                const request = {
                    openedPaymentId: props.openedPaymentId,
                    stripePaymentMethodId: paymentMethod.id,
                    firstName: props.firstName,
                    lastName: props.lastName,
                    email: props.email,
                    additionalEmail: props.additionalEmail,
                    additionalEmail2: props.additionalEmail2,
                    enableFuturePayments: props.futurePayments,
                    relatedTo: props.relatedTo,
                    chargeDescription: props.chargeDescription,
                    paymentDescription:props.paymentDescription,
                    rawPaymentMethod: paymentMethod,
                    selectedPaymentMethod: props.selectedPaymentMethod,
                    currency: props.currency.toLowerCase() ,
                    amount: props.rawAmount? props.rawAmount : "0",
                    recaptchaToken: props.recaptchaToken
                }
                try {
                    let result = await sendPay(request);
                    const d = result.data;
                    if (d.status === "succeeded") {
                        if (props.completionUrl) {
                            window.location.href = props.completionUrl;
                            /*MySwal.fire({
                                title: 'Successful payment!',
                                html: '<p>Your payment has been successfully recorded</p></p><p>You will be redirected in a few seconds.</p><br/><br/>',
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                timer: 10000,
                                willClose: () => {
                                    window.location.href = props.completionUrl;
                                }
                            })*/
                        } else {
                            MySwal.fire({
                                title: 'Successful payment!',
                                html: '<p>Your payment has been successfully recorded</p></p><p>now you can close this tab.</p><br/><br/>',
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                willClose: () => {
                                    window.close();
                                }

                            })
                        }
                    }
                } catch (error) {
                    recaptchaRef.current.reset();
                    props.clearOnRecaptcha();
                    //console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
                    if (error.response.data.code === "requires_action") {
                        console.log("requires_action");
                    } else {
                        let errorMsg = error.response.data.message;
                        if(errorMsg && errorMsg.includes("American Express in not enabled.")){
                            errorMsg = "American Express is not enabled."
                        }

                        try {
                            var jsonObject = JSON.parse(errorMsg);
                            if (jsonObject.hasOwnProperty("message")) {
                                errorMsg = jsonObject.message;
                            }
                        } catch (error) {
                            console.log(error)
                        }

                        MySwal.fire({
                            title: 'The payment could not be made',
                            text: errorMsg,
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                        })
                    }
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement className="form-control"/>
            <FuturesPayment companyName={ props.companyName }
                            setFuturePayments = { props.setFuturePayments}
                            forceSaveMop={ props.forceSaveMop}
                            futurePayments={ props.futurePayments }
                            formErrors={ props.formErrors } />
            <div className="recaptcha">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                    onChange={props.onRecaptchaChange}
                />
                <div className="text-danger">{props.formErrors.recaptcha}</div>
            </div>
            <Button fluid primary type="submit" style={{marginTop: 10}} className="btn-dark"  >Submit Payment</Button>
        </form>
    );
}

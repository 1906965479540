import {Component} from 'react';
import axios from "axios";

class Conf extends Component {
    constructor(props) {
        super(props);
        const host = window.location.host;
        if (host === "localhost:3000") {
            axios.interceptors.request.use(function (config) {
                config.url = "http://localhost:8083" + config.url;
                return config;
            })
        }
        axios.defaults.headers.common['Content-Type'] = 'application/json';
    }

    render() {
        return this.props.children;
    }
}

export default Conf;
import axios from "axios";
import {formatMoney} from "../utils/common";

export const sendPay = (payRequest) => {
    return axios.post("/opero/v1/pay", payRequest);
};

export const tokenizeQBCc = (payRequest) => {
    return axios.post("/opero/v1/qb/tokenizeCc", payRequest);
};

export const upsertMop = (updateRequest) => {
    return axios.post("/opero/v1/upsertMop", updateRequest);
};

export const calculateFee =  (amount,paymentMethod) =>{
    return axios.post("/opero/v1/calculateFee", {amount,paymentMethod });
}

export const doPrepareApp = async (prepareAppRequest) => {
    const result = await axios.post("/opero/v1/prepareApp", prepareAppRequest);
    const d = result.data;
    const paymentMethods = d.paymentMethods.filter((pm) => pm.active)
    const paymentMethod_default = paymentMethods.filter((pm) => pm.default)
    let paymentMethodId;

    if (prepareAppRequest.mop){
        paymentMethodId=paymentMethods.filter((pm) => pm.id===prepareAppRequest.mop)
        if (paymentMethodId.length===0){
            paymentMethodId=null
        }
        else{
            paymentMethodId=paymentMethodId[0].id
        }
    }

    if (!paymentMethodId) {
        if (!d.paymentMethod) {
            if (paymentMethod_default.length === 0) {
                const paymentMethod_mop = paymentMethods.filter((pm) => {
                    return pm.id === prepareAppRequest.mop || pm.name === prepareAppRequest.mop
                })
                if (paymentMethod_mop.length === 1) {
                    paymentMethodId = paymentMethod_mop[0].id
                } else {
                    paymentMethodId = d.paymentMethods.filter((pm) => pm.active)[0].id
                }
            } else {
                paymentMethodId = paymentMethod_default[0].id;
            }
        } else {
            paymentMethodId = d.paymentMethod.id;
        }
    }
    //console.log(paymentMethodId)
    let rawAmount = prepareAppRequest.amount ? prepareAppRequest.amount : 0;
    let currency = prepareAppRequest.currency ? prepareAppRequest.currency : d.defaultCurrency;
    let invoiceInfo = null;
    if (d.invoiceInfo) {
        currency = d.invoiceInfo.currency ? d.invoiceInfo.currency : d.defaultCurrency;
        invoiceInfo = {
            name: d.invoiceInfo.name,
            paymentDescription: d.invoiceInfo.paymentDescription,
            amountDue: d.invoiceInfo.amountDue,
            currency: currency,
            invoiceDescription: d.invoiceInfo.invoiceDescription,
            invoiceDocUrl: d.invoiceInfo.invoiceDocUrl,
        }
        rawAmount = d.invoiceInfo.amountDue;
    }

    return {
        orgId: prepareAppRequest.orgId,
        openedPaymentId: d.paymentId,
        companyLogo: d.companyLogo,
        logoImageHeight: d.logoImageHeight,
        logoImageWidth: d.logoImageWidth,
        companyName: d.companyName,
        paymentMethods: d.paymentMethods,
        paymentMethodId: paymentMethodId,
        relatedTo: prepareAppRequest.relatedTo,
        paymentDescription: prepareAppRequest.paymentDescription,
        chargeDescription: prepareAppRequest.chargeDescription,
        amount: formatMoney(prepareAppRequest.amount),
        currency: currency,
        skipValidate: prepareAppRequest.skipValidate,
        invoiceInfo: invoiceInfo,
        rawAmount: rawAmount,
        firstName: d.customer ? d.customer.billingContactFirstName : "",
        lastName: d.customer ? d.customer.billingContactLastName : "",
        email: d.customer ? d.customer.billingContactEmail : "",
        additionalEmail: d.customer ? (d.customer.billingContactAdditionalEmail ? d.customer.billingContactAdditionalEmail : "" ) : "",
        additionalEmail2: d.customer ? (d.customer.billingContactAdditionalEmail2 ? d.customer.billingContactAdditionalEmail2 : "" ) : "",
        customerId: d.customer ? d.customer.id : "",
        customerSavedPaymentMethod: d.customerSavedPaymentMethod ? d.customerSavedPaymentMethod : null,
        forceSaveMop: d.forceSaveMop
    }
}

export const doIsOrgConnected = async (request) => {
    return await axios.post("/opero/v1/isOrgConnected", request);
}

export const catchPrepareAppError = (err) => {
    if (err.response) {
        if (err.response.data.code && (err.response.data.code === "NO_PAYMENT" || err.response.data.code === "NO_ORG")) {
            err['message'] = "The payment link is invalid";
        }
    } else {
        err['message'] = "Service not available"
    }
    return err;
}

export const doQbNavigateToOauth = async (orgId, pmId, testMode) => {
    return axios.post("/opero/v1/qb/navigateToOauth", {orgId, pmId, testMode});
}

export const doQbAuthorize = async (realmId, authCode, pmId, openedPaymentId, orgId, testMode) => {
    return axios.post("/opero/v1/qb/authorize", {realmId, authCode, pmId, openedPaymentId, orgId, testMode});
}

export const doQbRevoke = async (orgId, pmId) => {
    return axios.post("/opero/v1/qb/revoke", {orgId, pmId});
}

export const oauthCallback = async (code, state) => {
    return axios.post(`/oauth/v1/uiCallback?code=${code}&state=${state}`);
}
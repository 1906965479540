import React from "react"
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Button} from "semantic-ui-react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { upsertMop} from "../../services/opero";
import ReCAPTCHA from "react-google-recaptcha";
import FuturesPaymentText from "./futuresPaymentText";
const MySwal = withReactContent(Swal)
const recaptchaRef = React.createRef();


export default function CheckOutFormUpdateMop(props) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        let errors = await props.validate();
        if (errors===0) {

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            MySwal.fire({
                imageUrl: './images/Spinner.gif',
                imageHeight: 120,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                MySwal.fire({
                    title: 'Oops...',
                    text: error.message,
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    CancelButtonText: "close",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                })
            } else {
                const request = {
                    openedPaymentId: props.openedPaymentId,
                    stripePaymentMethodId: paymentMethod.id,
                    firstName: props.firstName,
                    lastName: props.lastName,
                    email: props.email,
                    additionalEmail: props.additionalEmail,
                    additionalEmail2: props.additionalEmail2,
                    enableFuturePayments: props.futurePayments,
                    relatedTo: props.relatedTo,
                    rawPaymentMethod: paymentMethod,
                    selectedPaymentMethod: props.selectedPaymentMethod,
                    recaptchaToken: props.recaptchaToken
                }
                try {
                    let result = await upsertMop(request);
                    if (result.status === 200 ) {
                        if (props.completionUrl) {
                            MySwal.fire({
                                title: 'Billing Information Saved!',
                                html: '<p>The billing information entered has been successfully saved</p></p><p>You will be redirected in a few seconds.</p><br/><br/>',
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                timer: 10000,
                                willClose: () => {
                                    window.location.href = props.completionUrl;
                                }
                            })
                        } else {
                            MySwal.fire({
                                title: 'Billing Information Saved!',
                                html: '<p>The billing information entered has been successfully saved</p></p><p>You may now close this tab.</p><br/><br/>',
                                icon: 'success',
                                showCloseButton: false,
                                showCancelButton: false,
                                allowOutsideClick: false,
                                showConfirmButton: false,
                                backdrop: '#fff',
                                willClose: () => {
                                    window.close();
                                }

                            })
                        }
                    }
                } catch (error) {
                    recaptchaRef.current.reset();
                    props.clearOnRecaptcha();
                    //console.error(error.response.data);     // NOTE - use "error.response.data` (not "error")
                    if (error.response.data.code === "requires_action") {
                        console.log("requires_action");
                    } else {
                        MySwal.fire({
                            title: 'The payment method could not be updated',
                            text: error.response.data.message,
                            icon: 'warning',
                            showCloseButton: true,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                        })
                    }
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement className="form-control"/>
            <FuturesPaymentText companyName={ props.companyName } setFuturePayments = { props.setFuturePayments} />
            <div className="recaptcha mt-4">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                    onChange={props.onRecaptchaChange}
                />
                <div className="text-danger">{props.formErrors.recaptcha}</div>
            </div>
            <Button fluid primary type="submit" style={{marginTop: 10}} className="btn-dark"  >Update payment method</Button>
        </form>
    );
}

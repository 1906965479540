export const localStorageKey = "pay.opero";

export const setLocalStorage = (dataObj) => {
    localStorage.setItem(localStorageKey, JSON.stringify(dataObj));
}

export const getLocalStorage = () => {
    return JSON.parse(localStorage.getItem(localStorageKey));
}

export const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})?$)/i);


export const parsePaymentFormQueryParams = (locationSearch) => {
    const params = new URLSearchParams(locationSearch);
    let amount=null;
    if (params.get('amount')){

        const numberValidator = /^-?\d*(\.\d+)?$/
        if (numberValidator.test(params.get('amount'))){
            let floatAmount=parseFloat(params.get('amount')).toFixed(2)
            amount=(floatAmount*100).toString()
        }
        else{
            amount='-1'
        }

    }


    return [params, {
        orgId: params.get('orgId') || params.get('orgid'),
        invId: params.get('inv'),
        completionUrl: params.get('completionUrl') || params.get('completionurl'),
        mop: params.get('mop'),
        relatedTo: params.get('relatedTo') || params.get('relatedto'),
        paymentDescription: params.get('paymentDescription') || params.get('paymentdescription'),
        chargeDescription: params.get('chargeDescription') || params.get('chargedescription'),
        amount: amount,
        //amount: params.get('amount') ? params.get('amount').replace(/[^\d]/g, '') : null,
        currency: params.get('currency') ? params.get('currency') : '',
        customerId: params.get('customerId') || params.get('customerid') || params.get('customer'),
        skipValidate: params.get('skipValidate') || params.get('skipvalidate'),
        testMode: params.get('testMode') || params.get('testmode'),
    }];
}

export const formatMoney = (num) => {
    return (num/100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const validateCCNumber = (value) => {
    let result = false;
    if (value.length>10){
        if (/[^0-9-\s]+/.test(value)) return false;
        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");
        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);
            if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
            nCheck += nDigit;
            bEven = !bEven;
        }
        result = (nCheck % 10) === 0;
    }
    return result;
}
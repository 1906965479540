import React from "react"

export default function FuturesPayment(props) {


    return (
            <fieldset className="form-group row m-0" id="form-group-future-payments">
                <div className="col-sm-12">
                    { `By clicking submit I authorize ${props.companyName} to process entries to the bank account/credit card above in order to pay amount due for future invoices. I represent that I have authority to bind the organization that owns the bank account/credit card, and to authorize all transactions to the bank account/credit card.`}
                </div>
            </fieldset>
    );
}

import React, {Component} from 'react';
import {doIsOrgConnected, doQbNavigateToOauth} from "../services/opero";
import {Loader} from "semantic-ui-react";
import {ErrorMessage} from "../components/messages/errorMessage";
import {setLocalStorage} from "../utils/common";

class QbConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        }
    }

    async componentDidMount() {
        this.setState({error: null});

        const params = new URLSearchParams(this.props.location.search);
        const orgId = params.get('orgId');
        const pmId = params.get('pmId');
        const testMode = params.get('testMode');
        let testModeBoolean = true;
        if(testMode === "False") {
            testModeBoolean = false;
        }
        if (orgId.length > 0 && pmId.length > 0) {
            try {
                await doIsOrgConnected({orgId, pmId});
                this.setState({
                    error: null,
                });
                setLocalStorage({pmId: pmId, orgId: orgId, openedPaymentId: null, testMode: testModeBoolean});
                const result = await doQbNavigateToOauth(orgId, pmId, testModeBoolean);
                window.location = result.data;
            } catch (err) {
                console.log(err);
                this.setState({loading: false, error: {message: "SF organization not connected"}});
            }
        } else {
            this.setState({loading: false, error: {message: "The url is invalid"}});
        }
    }

    render() {
        const {error} = this.state;
        if (error) {
            return <ErrorMessage message={error.message}/>
        }
        return <Loader active>Loading</Loader>
    }
}

export default QbConnect;

import React from 'react';

import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import PaymentForm from './views/paymentForm'
import PaymentFormUpdateMop from './views/paymentFormUpdateMop'
import QbConnect from "./views/qbConnect";
import QbDisconnect from "./views/qbDisconnect";
import Conf from "./components/conf";
import QbSuccess from "./views/qbSuccess";
import MessageSuccess from './views/messageSuccess';
import MessageError from './views/messageError';
import OauthCallback from "./views/oauthCallback";

function App() {
    return (
        <Router>
            <Conf className="App">
                <Route exact path="/" component={PaymentForm}/>
                <Route exact path="/updateMop/" component={PaymentFormUpdateMop}/>
                <Route exact path={"/qb/connect/"} component={QbConnect}/>               {/* /qb/connect?orgId=123&pmId=123 */}
                <Route exact path={"/qb/disconnect/"} component={QbDisconnect}/>         {/* /qb/disconnect?orgId=123&pmId=123 */}
                <Route exact path={"/qb/success/"} component={QbSuccess}/>
                <Route exact path={"/oauth/v1/success/"} component={MessageSuccess}/>    {/* /message/success?message=test error */}
                <Route exact path={"/oauth/v1/error/"} component={MessageError}/>        {/* /message/success?message=test error */}
                <Route exact path={"/oauth/v1/callback"} component={OauthCallback}/>     {/* /oauth/v1/callback?code=xxxx&state=xxxx */}
            </Conf>
        </Router>
    );
}

export default App;
import React, {Component} from 'react';
import {Button, Loader} from "semantic-ui-react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import './paymentForm.css'
import {Background} from "../components/background/background";
import CheckoutForm from "../components/forms/checkOutForm";
import {ErrorMessage} from "../components/messages/errorMessage";
import "react-multi-email/style.css";
import FuturesPayment from "../components/forms/futuresPayment";
import {calculateFee, catchPrepareAppError, doPrepareApp, sendPay, tokenizeQBCc } from '../services/opero';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import {emailPattern, formatMoney, parsePaymentFormQueryParams, setLocalStorage, validateCCNumber} from "../utils/common";
const MySwal = withReactContent(Swal)
const recaptchaRef = React.createRef();


const GetFee=(props) => {
    const currentPM=props.el.state.paymentMethods?.filter((pm) => { return pm.id===props.el.state.paymentMethodId });
    return (<div>
        { props.el.state.amountWithFee &&
            <div>
                <p> Transaction Fee: <b>{props.el.state.fee} { props.el.state.currency }</b></p>
                <p>Transaction Fee Description: <b>{currentPM[0]?.transactionFeeDescription}</b></p>
                <p> Updated Total: <b>{props.el.state.amountWithFee} { props.el.state.currency }</b></p>
            </div>
        }
    </div>)
}

const MakePayment = (props) => {
    if (props.invoiceInfo){
        return (<div>
            <h4>Make a Payment</h4>
            { props.invoiceInfo.name ? <p>For Invoice #{props.invoiceInfo.name}</p> : <p>For #{props.invoiceInfo.invoiceDescription}</p> }
            <p>Amount Due is <b>{ formatMoney(props.invoiceInfo.amountDue) } { props.invoiceInfo.currency }</b></p>
            { props.invoiceInfo.invoiceDocUrl && <p><a href={props.invoiceInfo.invoiceDocUrl} target="_blank" rel="noopener noreferrer">View Invoice</a></p>  }
            { props.invoiceInfo.invoiceDescription && <p>{props.invoiceInfo.invoiceDescription} </p>  }
            <GetFee el={props.el}></GetFee>
        </div>)
    } else {
        return (<div key="111">
            { props.chargeDescription && <div><h4>Make a Payment</h4><p>{props.chargeDescription} </p></div>}
            <h4>Pay</h4>

            <label htmlFor="amount">Amount</label><br/>
            <div className="input-group mb-3">
                <input type="text" className="form-control col-4" name="amount" value={props.el.state.amount} onChange={(event) => props.el.handleValidAmount(event)}
                       aria-label="Recipient's username" aria-describedby="basic-addon2"  />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">{ props.el.state.currency.toUpperCase() }</span>
                    </div>
            </div>
            <div className="text-danger">{props.el.state.formErrors.amount}</div>
        </div>)
    }
}

class PaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            formErrors: {},
            orgId: null,
            openedPaymentId: null,
            completionUrl: null,
            paymentMethods:[],
            paymentMethodId:null,
            theme: null,
            firstName:"",
            lastName:"",
            email:"",
            additionalEmail:"",
            additionalEmail2:"",
            futurePayments:true,
            validBillingData:true,
            invoiceInfo: null,
            relatedTo:null,
            chargeDescription:null,
            amount:'',
            currency:'',
            rawAmount:0,
            bank_account_routing: "",
            bank_account_number: "",
            bank_account_name: "",
            bank_account_phone: "",
            bank_account_type:"BUSINESS_CHECKING",
            card_name:"",
            card_number:"",
            card_expMonth:"",
            card_expYear:"",
            card_cvc:"",
            quickBooksCcId:null,
            skipValidate: 0,
            recaptchaToken: null,
            stripePublishableKey:null,
            customerSavedPaymentMethod:null,
            savePayment:"yes",
            amountWithFee:null,
            forceSaveMop:false,
            fee: null
        };
    }

    async componentDidMount() {

        this.setState({loading: true, error: null});
        const [params, queryParamsData] = parsePaymentFormQueryParams(this.props.location.search);
        //console.log("queryParamsData",queryParamsData)
        this.setState({theme: params.get('theme'), completionUrl: queryParamsData.completionUrl });
        if (queryParamsData.orgId) {
            try {
                const {data, ...prepareAppResponse} = await doPrepareApp(queryParamsData);
                //console.log(prepareAppResponse);
                if (prepareAppResponse.forceSaveMop){
                    this.state.futurePayments=false;
                }

                this.setState({
                    loading: false,
                    error: null,
                    ...prepareAppResponse,
                });
            } catch (err) {
                //console.log("err",err)
                err = catchPrepareAppError(err);
                this.setState({loading: false, error: err});
            }
        } else {
            this.setState({loading: false, error: {message: "The payment link is invalid"}});
        }

        await this.updateAmountWithFee();
    }

    async updateAmountWithFee(paymentMethodsId=null) {
        let currentPM
        if (paymentMethodsId){
            currentPM = this.state.paymentMethods?.filter((pm) => {
                return pm.id === paymentMethodsId
            });
        }else if (this.state.customerSavedPaymentMethod){
            currentPM = this.state.paymentMethods?.filter((pm) => {
                return pm.id === this.state.customerSavedPaymentMethod.paymentMethodId
            });
        }else{
            currentPM = this.state.paymentMethods?.filter((pm) => {
                return pm.default === true
            });
        }


        let amount = this.state.amount
        if (this.state.invoiceInfo?.amountDue) {
            amount = this.state.invoiceInfo.amountDue
        }

        if (currentPM[0]?.transactionFeeCharged > 0 || currentPM[0]?.transactionFeeFlat > 0) {
            let result = await calculateFee(amount, currentPM[0]);
            if (result.status === 200) {
                const totalAmount=parseInt(amount) + parseInt(result.data?.fee)
                this.setState({amountWithFee: formatMoney( totalAmount )})
                this.setState({fee: formatMoney( result.data?.fee )})
            }
        } else {
            this.setState({amountWithFee: null })
            this.setState({fee: null })
        }
    }
    handleUserInput (e) {
        const name = e.target.name;
        let value = e.target.value;

        if (name==="card_expMonth" || name==="card_expYear"){
            let x="0"+value
            value=x.substr(x.length - 2);
        }
        this.setState({[name]: value});
        if (name ==="paymentMethodId"){
            this.updateAmountWithFee(value)
            const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
            this.setState({stripePublishableKey: currentPM[0].stripePublishableKey});
        }
        return true
    }

    handleStripePromise (){
        const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
        //console.log(currentPM[0].stripePublishableKey);
        if (currentPM[0].stripePublishableKey){
            return loadStripe(currentPM[0].stripePublishableKey);
        }
        else{
            return false;
        }
    }

    handleIsQBBank(){
        const currentPM=this.state.paymentMethods.filter((pm) => { return pm.id===this.state.paymentMethodId });
        return currentPM[0].type === "BANK" && currentPM[0].platform === "QB";
    }

    handleValidAmount(e){
        const name = e.target.name;
        let filteredInput = e.target.value.replace(/[^0-9.]/g, '');
        let output=filteredInput;
        let rawOut=filteredInput.split(".")

        if (rawOut.length>2){
            filteredInput="";
            for (let i = 0; i < rawOut.length; i++) {
                if( i === rawOut.length-1 ){
                    filteredInput=filteredInput+"."
                    rawOut[i]=rawOut[i].slice(0,1)
                    //console.log("ultimo",rawOut[i])
                }
                filteredInput=filteredInput+rawOut[i]
            }
            output=filteredInput;
        }
        else if (rawOut.length===2){
            let add_decimal=rawOut[1].slice(0,2).padEnd(2, '0')
            filteredInput=rawOut[0]+"."+add_decimal;
            output= rawOut[0]+"."+rawOut[1].slice(0,2)
        }
        else if(rawOut.length===1){
            filteredInput= rawOut[0]+"00"
        }
        this.setState({[name]: output,
                            rawAmount:filteredInput.replace('.', '')});
        return true;
    }

    castToString(val){
        //console.log("CASTING TO STR", {val}, val+"")
        return val + "";
    }

    validate(){
        let errors = {};
        let isValid = true;

        if (!this.state.skipValidate) {

            /*if ( parseInt(this.state.rawAmount)<1) {
                isValid = false;
                errors["amount"] = "Please enter a valid amount.";
            }*/

            if (this.state.firstName.trim().length < 2) {
                isValid = false;
                errors["firstName"] = "Please enter your name.";
            }

            if (this.state.lastName.trim().length < 2) {
                isValid = false;
                errors["lastName"] = "Please enter your last name.";
            }

            if (!emailPattern.test(this.state.email)) {
                isValid = false;
                errors["email"] = "Please enter a valid email address.";
            }

            if (this.state.additionalEmail.trim().length !== 0 && !emailPattern.test(this.state.additionalEmail)) {
                isValid = false;
                errors["additionalEmail"] = "Please enter valid additional email address.";
            }

            if (this.state.additionalEmail2.trim().length !== 0 && !emailPattern.test(this.state.additionalEmail2)) {
                isValid = false;
                errors["additionalEmail2"] = "Please enter valid additional email address.";
            }
            if (this.state.forceSaveMop && !this.state.futurePayments){
                isValid = false;
                errors["futurePayments"] = "Please authorize saving your payment method.";
            }

            if (!this.handleStripePromise() && (this.state.savePayment!=="yes")) {
                if (this.handleIsQBBank()) {
                    if (this.state.bank_account_routing.trim().length !== 9) {
                        isValid = false;
                        errors["bank_account_routing"] = "ACH routing number invalid.";
                    }

                    if (this.state.bank_account_number.trim().length < 7) {
                        isValid = false;
                        errors["bank_account_number"] = "ACH account number invalid.";
                    }

                    if (this.state.bank_account_name.trim().length < 2) {
                        isValid = false;
                        errors["bank_account_name"] = "Please enter your bank account name.";
                    }

                    if (this.state.bank_account_phone.trim().length < 7) {
                        isValid = false;
                        errors["bank_account_phone"] = "Please enter your bank account phone.";
                    }
                }
                else{
                    if (this.state.card_name.trim().length < 2) {
                        isValid = false;
                        errors["card_name"] = "Please enter a valid card name.";
                    }
                    if (this.state.card_number.trim().length < 10) {
                        isValid = false;
                        errors["card_number"] = "Please enter a valid card number.";
                    }
                    if (!validateCCNumber(this.state.card_number.trim())){
                        isValid = false;
                        errors["card_number"] = "Please enter a valid card number.";
                    }
                    if (this.state.card_expMonth.trim().length!==2) {
                        isValid = false;
                        errors["card_expMonth"] = "Please enter a valid expire month.";
                    }
                    if (this.state.card_expYear.trim().length!==2) {
                        isValid = false;
                        errors["card_expYear"] = "Please enter a valid expire year.";
                    }
                    if (this.state.card_cvc.trim().length < 2) {
                        isValid = false;
                        errors["card_cvc"] = "Please enter a valid CVC.";
                    }
                }

            }

            if (!this.state.recaptchaToken){
                isValid = false;
                errors["recaptcha"] = "Please complete the recaptcha.";
            }

            this.setState({
                formErrors: errors,
                validBillingData: isValid
            });
        }
        //console.log(errors)
        return Object.keys(errors).length
    }

    setFuturePayments =  (childData) => {
        this.setState({futurePayments: childData})
    }

    clearOnRecaptcha =  () => {
        this.setState({ recaptchaToken: null })
    }

    onRecaptchaChange = (value) => {
        this.setState({ recaptchaToken: value })
    }

    handleOptionChange =  function (changeEvent) {
        //console.log(changeEvent)
        this.setState({ savePayment: changeEvent.currentTarget.value })
    }

    async payWithSavePM(){
        let errors = this.validate();
        if (errors===0) {
            MySwal.fire({
                imageUrl: './images/Spinner.gif',
                imageHeight: 120,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            const request = {
                openedPaymentId: this.state.openedPaymentId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                additionalEmail: this.state.additionalEmail,
                additionalEmail2: this.state.additionalEmail2,
                enableFuturePayments: this.state.futurePayments,
                relatedTo: this.state.relatedTo,
                chargeDescription: this.state.chargeDescription,
                paymentDescription:this.state.paymentDescription,
                currency: this.state.currency,
                amount: this.state.rawAmount? this.state.rawAmount : "0",
                recaptchaToken: this.state.recaptchaToken,
                qbCustomerId: this.state.qbCustomerId,
                customerSavedPaymentMethod: this.state.customerSavedPaymentMethod,
                selectedPaymentMethod: this.state.customerSavedPaymentMethod.paymentMethod
                }

            try {
                setLocalStorage({
                    orgId: this.state.orgId,
                    pmId: this.state.paymentMethods.filter((pm) => pm.id === this.state.paymentMethodId)[0].id,
                    openedPaymentId: this.state.openedPaymentId,
                    testMode: null,
                });

                let result = await sendPay(request);
                if (result.status === 200) {
                    if (this.state.completionUrl) {
                        window.location.href = this.state.completionUrl;
                        /*MySwal.fire({
                            title: 'Successful payment!',
                            html: '<p>Your payment has been successfully recorded</p></p><p>You will be redirected in a few seconds.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            timer: 10000,
                            willClose: () => {
                                window.location.href = this.state.completionUrl;
                            }
                        })*/
                    } else {
                        MySwal.fire({
                            title: 'Successful payment!',
                            html: '<p>Your payment has been successfully recorded</p></p><p>now you can close this tab.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            willClose: () => {
                                window.close();
                            }

                        })
                    }
                }
            } catch (error) {
                recaptchaRef.current.reset();
                this.clearOnRecaptcha()
                if (error.response?.data?.errorCode === "QB_NOT_AUTHORIZED") {
                    window.location = error.response.data.redirectUrl;
                } else {
                    setLocalStorage({});
                    let html = error.response.data.message || '';

                    if (error.response.data.code === "QB_ERR") {
                        let message = error.response.data.message
                        try {
                            message = JSON.parse(error.response.data.message)
                        } catch {
                        }

                        if (message.errors !== undefined) {
                            html = ""
                            message.errors.forEach(function (value) {
                                if (value.moreInfo !== undefined) {
                                    html = html + value.moreInfo + "<br/>"
                                } else if (value.message !== undefined) {
                                    html = html + value.message + "<br/>"
                                }
                            });
                        }
                    }
                    MySwal.fire({
                        title: 'The payment could not be made',
                        html: html,
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })
                }
            }
        }
    }

    async payWithQB() {
        let errors = this.validate();

        if (errors===0){
            MySwal.fire({
                imageUrl: './images/Spinner.gif',
                imageHeight: 120,
                imageAlt: 'Loading...',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })


            if (!this.handleIsQBBank()){
                const request = {
                    name: this.state.card_name,
                    number: this.state.card_number,
                    expMonth: this.state.card_expMonth,
                    expYear: "20"+this.state.card_expYear,
                    cvc: this.state.card_cvc,
                    email: this.state.email,
                    openedPaymentId: this.state.openedPaymentId,
                    selectedPaymentMethod: this.state.paymentMethods.filter((pm) => pm.id === this.state.paymentMethodId)[0],
                }
                try {
                    let result = await tokenizeQBCc(request);
                    if (result.status === 200) {
                        this.setState({quickBooksCcId: result.data.id, qbCustomerId: result.data.customerId });
                    }
                }
                catch (error){
                    let html=error.response.data.message || '';
                    if (error.response.data.code==="QB_TOKENIZE_ERR" || error.response.data.code==="QB_ERR"){
                        let message=error.response.data.message
                        try {
                            message = JSON.parse(error.response.data.message)
                        }catch{}
                        if(message.errors!== undefined){
                            html=""
                            message.errors.forEach( function(value) {
                                if (value.moreInfo!==undefined){
                                    html = html + value.moreInfo + "<br/>"
                                }
                                else if ( value.message!==undefined){
                                    html = html + value.message + "<br/>"
                                }
                            });
                        }
                    }

                    MySwal.fire({
                        title: 'The card could not be processed',
                        html: html,
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })
                    return false;
                }
            }

            const request = {
                openedPaymentId: this.state.openedPaymentId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                additionalEmail: this.state.additionalEmail,
                additionalEmail2: this.state.additionalEmail2,
                enableFuturePayments: this.state.futurePayments,
                relatedTo: this.state.relatedTo,
                chargeDescription: this.state.chargeDescription,
                paymentDescription:this.state.paymentDescription,
                currency: this.state.currency,
                amount: this.state.rawAmount? this.state.rawAmount : "0",
                BankAccountRouting: this.state.bank_account_routing,
                BankAccountNumber: this.state.bank_account_number,
                BankAccountName: this.state.bank_account_name,
                BankAccountPhone: this.state.bank_account_phone,
                BankAccountType: this.state.bank_account_type,
                quickBooksCcId: this.state.quickBooksCcId,
                selectedPaymentMethod: this.state.paymentMethods.filter((pm) => pm.id === this.state.paymentMethodId)[0],
                recaptchaToken: this.state.recaptchaToken,
                last4: this.state.card_number.substr(this.state.card_number.length - 4),
                expMonth: this.state.card_expMonth,
                expYear: "20"+this.state.card_expYear,
                qbCustomerId: this.state.qbCustomerId
            }

            try{
                setLocalStorage({
                    orgId: this.state.orgId,
                    pmId: this.state.paymentMethods.filter((pm) => pm.id === this.state.paymentMethodId)[0].id,
                    openedPaymentId: this.state.openedPaymentId,
                    testMode: null,
                });

                let result = await sendPay(request);
                if (result.status === 200){
                    if (this.state.completionUrl){
                        window.location.href = this.state.completionUrl;
                        /*MySwal.fire({
                            title: 'Successful payment!',
                            html: '<p>Your payment has been successfully recorded</p></p><p>You will be redirected in a few seconds.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            timer: 10000,
                            willClose: () => {
                                window.location.href = this.state.completionUrl;
                            }
                        })
                        */
                    }else{
                        MySwal.fire({
                            title: 'Successful payment!',
                            html: '<p>Your payment has been successfully recorded</p></p><p>now you can close this tab.</p><br/><br/>',
                            icon: 'success',
                            showCloseButton: false,
                            showCancelButton: false,
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            backdrop: '#fff',
                            willClose: () => {
                                window.close();
                            }

                        })
                    }
                }
            }
            catch (error) {
                recaptchaRef.current.reset();
                this.clearOnRecaptcha()
                if(error.response?.data?.errorCode === "QB_NOT_AUTHORIZED"){
                    window.location = error.response.data.redirectUrl;
                } else {
                    setLocalStorage({});
                    let html=error.response.data.message || '';

                    if (error.response.data.code==="QB_ERR"){
                        let message=error.response.data.message
                        try{
                            message=JSON.parse(error.response.data.message)
                        }
                        catch{}

                        if(message.includes("American Express in not enabled.")){
                            message = "American Express is not enabled."
                        }

                        if(message.errors!== undefined) {
                            html=""
                            message.errors.forEach(function (value) {
                                if (value.moreInfo!==undefined){
                                    html = html + value.moreInfo + "<br/>"
                                }
                                else if ( value.message!==undefined){
                                    html = html + value.message + "<br/>"
                                }
                            });
                        }
                    }
                    MySwal.fire({
                        title: 'The payment could not be made',
                        html: html,
                        icon: 'warning',
                        showCloseButton: true,
                        showCancelButton: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                    })
                }
            }
        }
    }

    render() {
        const {loading, error, orgId, invoiceInfo} = this.state;
        if (loading) {
            return <Loader active>Loading</Loader>
        } else if (error) {
            return <ErrorMessage message={error.message}/>
        } else if (!orgId) {
            return <ErrorMessage message="The payment link is invalid"/>
        } else if (invoiceInfo && invoiceInfo.hasOwnProperty('amountDue') && invoiceInfo.amountDue <= 0){
            MySwal.fire({
                title: 'This invoice has been paid!',
                icon: 'success',
                showCloseButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                backdrop: '#fff',
            })
            return <React.Fragment>&nbsp;</React.Fragment>
        }
        else if ( parseFloat(this.state.amount)<0 ){
            return <ErrorMessage message="The payment link is invalid"/>
        }

        let optionTemplate = this.state.paymentMethods.map(v => (
            <option key={v.id} value={v.id}>{v.name}</option>
        ));

        const QBForm = this.handleIsQBBank() ? <div>
                    <div className="form-row">
                        <div className="form-group col-md-8">
                            <label htmlFor="bank_account_name" >Bank Account Name</label>
                            <input type="text" className="form-control" name="bank_account_name" placeholder="The name on the bank account" value={this.state.bank_account_name} onChange={(event) => this.handleUserInput(event)}  />
                            <div className="text-danger">{this.state.formErrors.bank_account_name}</div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="bank_account_phone">Bank Account Phone Number</label>
                            <input type="number" className="form-control" name="bank_account_phone" placeholder="Phone number associated to your account" value={this.state.bank_account_phone} onChange={(event) => this.handleUserInput(event)}  />
                            <div className="text-danger">{this.state.formErrors.bank_account_phone}</div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="bank_account_type">Bank Account Type</label>
                            <select className="form-control" name="bank_account_type" value={this.state.bank_account_type} onChange={(event) => this.handleUserInput(event)} >
                                <option value="BUSINESS_CHECKING">Business Checking</option>
                                <option value="BUSINESS_SAVINGS">Business Savings</option>
                                <option value="PERSONAL_CHECKING">Personal Checking</option>
                                <option value="PERSONAL_SAVINGS">Personal Savings</option>
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="bank_account_number">Bank Account Number</label>
                            <input type="number" className="form-control" name="bank_account_number" value={this.state.bank_account_number} onChange={(event) => this.handleUserInput(event)}  />
                            <div className="text-danger">{this.state.formErrors.bank_account_number}</div>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="bank_account_routing">Bank Account Routing Number</label>
                            <input type="number" className="form-control" name="bank_account_routing" value={this.state.bank_account_routing} onChange={(event) => this.handleUserInput(event)}  />
                            <div className="text-danger">{this.state.formErrors.bank_account_routing}</div>
                        </div>
                    </div>
                </div>
            : <div>
                <div className="form-row">

                    <div className="form-group col-md-8">
                        <label htmlFor="card_number">Credit Card Number</label>
                        <input type="number" className="form-control" name="card_number" placeholder="" value={this.state.card_number} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_number}</div>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="card_expire">Expiration</label>
                        <input type="number" className="form-control" name="card_expMonth" placeholder="MM" value={this.state.card_expMonth} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_expMonth}</div>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="card_expire">&nbsp;</label>
                        <input type="number" className="form-control" name="card_expYear" placeholder="YY" value={this.state.card_expYear} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_expYear}</div>
                    </div>

                    <div className="form-group col-md-8">
                        <label htmlFor="card_name" >Name On Credit Card</label>
                        <input type="text" className="form-control" name="card_name" placeholder="" value={this.state.card_name} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_name}</div>
                    </div>

                    <div className="form-group col-md-4">
                        <label htmlFor="bank_account_number">CVC</label>
                        <input type="number" className="form-control" name="card_cvc" value={this.state.card_cvc} onChange={(event) => this.handleUserInput(event)}  />
                        <div className="text-danger">{this.state.formErrors.card_cvc}</div>
                    </div>
                </div>
            </div>

        return (
            <React.Fragment>
                <Background theme={ this.state.theme }/>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div  className="col-lg-7 col-md-9 col-sm-11  mt-4 mb-4">
                            <div id="form-container" className="row p-0 mt-4 mb-4">
                                <div className="col-md-12 p-4">
                                    <div className="company-info mb-4">{ this.state.companyLogo ? <img src={ this.state.companyLogo } alt=""  width={this.state.logoImageWidth} height={this.state.logoImageHeight} /> : <h2 className="m-0" id="company-name">{ this.state.companyName }</h2>  }</div>
                                    <div className="card-body mb-4">
                                        <MakePayment  key="makePayment" invoiceInfo={invoiceInfo} chargeDescription={this.state.chargeDescription} el={this} />
                                        <h4>Billing Contact</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="firsName" >First Name</label>
                                                <input type="text" className="form-control" name="firstName" value={this.state.firstName} onChange={(event) => this.handleUserInput(event)}  />
                                                <div className="text-danger">{this.state.formErrors.firstName}</div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" className="form-control" name="lastName" value={this.state.lastName} onChange={(event) => this.handleUserInput(event)}  />
                                                <div className="text-danger">{this.state.formErrors.lastName}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" name="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.email}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="additionalEmail">Additional Billing Email (optional)</label>
                                                <input type="email" className="form-control" name="additionalEmail" value={this.state.additionalEmail} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.additionalEmail}</div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="additionalEmail2">Additional Billing Email 2 (optional)</label>
                                                <input type="email" className="form-control" name="additionalEmail2" value={this.state.additionalEmail2} onChange={(event) => this.handleUserInput(event)} />
                                                <div className="text-danger">{this.state.formErrors.additionalEmail2}</div>
                                            </div>
                                        </div>
                                        <h4>Payment Method</h4>
                                        {this.state.customerSavedPaymentMethod &&
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio"
                                                               name="savePayment" id="gridRadios1" value="yes"
                                                               defaultChecked onChange={(event) => this.handleOptionChange(event)} />
                                                        <label className="form-check-label" htmlFor="gridRadios1">
                                                            Charge { this.state.customerSavedPaymentMethod.paymentMethod.name } on file ending in { this.state.customerSavedPaymentMethod.creditCardLast4 || this.state.customerSavedPaymentMethod.bankAccountLast4 }
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-1 mb-4">
                                                        <input className="form-check-input" type="radio"
                                                               name="savePayment" id="gridRadios2" value="no" onChange={(event) => this.handleOptionChange(event)} />
                                                        <label className="form-check-label " htmlFor="gridRadios2">
                                                            Enter a New Payment Method.
                                                        </label>
                                                    </div>
                                                    {this.state.savePayment === "yes" && <div>
                                                        <FuturesPayment companyName={this.state.companyName}
                                                                        setFuturePayments={this.setFuturePayments}
                                                                        forceSaveMop={ this.state.forceSaveMop}
                                                                        futurePayments= {this.state.futurePayments}
                                                                        formErrors={ this.state.formErrors }
                                                                        className="mb-2"/>
                                                        <div className="recaptcha">
                                                            <ReCAPTCHA
                                                                ref={recaptchaRef}
                                                                sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                                                                onChange={this.onRecaptchaChange}
                                                            />
                                                            <div className="text-danger">{this.state.formErrors.recaptcha}</div>
                                                        </div>
                                                        <Button fluid primary type="bottom" style={{marginTop: 10}}
                                                                className="btn-dark"
                                                                onClick={this.payWithSavePM.bind(this)}>Submit
                                                            Payment</Button>
                                                        </div>
                                                    }


                                                </div>

                                            </div>
                                        }
                                        { (!this.state.customerSavedPaymentMethod || this.state.savePayment === "no") &&
                                        <div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="methods">Select Payment Method</label>
                                                    <select className="form-control" name="paymentMethodId"
                                                            value={this.state.paymentMethodId}
                                                            onChange={(event) => this.handleUserInput(event)}>
                                                        {optionTemplate}
                                                    </select>
                                                </div>
                                            </div>

                                            {this.handleStripePromise() ?
                                                <Elements stripe={this.handleStripePromise()}
                                                          key={this.state.stripePublishableKey}>
                                                    <CheckoutForm companyName={this.state.companyName}
                                                                  lastName={this.state.lastName}
                                                                  firstName={this.state.firstName}
                                                                  email={this.state.email}
                                                                  openedPaymentId={this.state.openedPaymentId}
                                                                  completionUrl={this.state.completionUrl}
                                                                  validBillingData={this.state.validBillingData}
                                                                  additionalEmail={this.state.additionalEmail}
                                                                  additionalEmail2={this.state.additionalEmail2}
                                                                  futurePayments={this.state.futurePayments}
                                                                  setFuturePayments={this.setFuturePayments}
                                                                  selectedPaymentMethod={this.state.paymentMethods.filter((pm) => {
                                                                      return pm.id === this.state.paymentMethodId
                                                                  })[0]}
                                                                  currency={this.state.currency}
                                                                  rawAmount={this.state.rawAmount}
                                                                  stripe={this.handleStripePromise()}
                                                                  validate={this.validate.bind(this)}
                                                                  formErrors={this.state.formErrors}
                                                                  relatedTo={this.state.relatedTo}
                                                                  chargeDescription={this.state.chargeDescription}
                                                                  paymentDescription={this.state.paymentDescription}
                                                                  recaptchaToken={this.state.recaptchaToken}
                                                                  onRecaptchaChange={this.onRecaptchaChange}
                                                                  clearOnRecaptcha={this.clearOnRecaptcha}
                                                                  forceSaveMop={ this.state.forceSaveMop}
                                                    />
                                                </Elements>
                                                : <div>{QBForm}
                                                    <FuturesPayment companyName={this.state.companyName}
                                                                    setFuturePayments={this.setFuturePayments}
                                                                    forceSaveMop={ this.state.forceSaveMop}
                                                                    formErrors={ this.state.formErrors } />
                                                    <div className="recaptcha">
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey="6LeQ-lMdAAAAABfCB7qQsosCAzfxKeXBeEKjGKGC"
                                                            onChange={this.onRecaptchaChange}
                                                        />
                                                        <div
                                                            className="text-danger">{this.state.formErrors.recaptcha}</div>
                                                    </div>
                                                    <Button fluid primary type="bottom" style={{marginTop: 10}}
                                                            className="btn-dark" onClick={this.payWithQB.bind(this)}>Submit
                                                        Payment</Button>
                                                </div>
                                            }
                                        </div>
                                        }
                                        <p className="powerBy mt-4 mb-0">Powered by <a href="https://www.opero.com" target="blank">Opero</a> for { this.state.companyName } </p>
                                        <p className="securelyBy mt-0">Payment Processed Securely By { this.handleStripePromise() ? <i className="fab fa-stripe"> </i> : <b>QuickBooks</b>} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PaymentForm;
